import { useNuxtApp } from "nuxt/app";
import { defineStore } from "pinia";
import axios from "axios";
import _ from "lodash";
import helper from "../resources/js/libraries/helper";
import { useSsrFetch } from "../composables/useFetch";
import type { DataSource, FilterItem, FilterOptions, FilterPair } from "../types/filters.type"

export const useProjectsFiltersStore = defineStore("projects-filters", {
	state: () => ({
		slug: '',
		filters: [] as FilterItem[],
		data: [],
		count: 0,
		selected_filters: {
			developer: {},
			category: {},
			bedrooms: {},
			price: {},
			status: {},
		} as FilterOptions,
		urlDefaults: null as FilterOptions | null,
		filter_url: '',
		style: {
			location: 'inline-flex mb-0',
			buttonBackground: 'bg-gray-100',
		},
		showInputLabel: true,
		customQuery: {
			location: { has_projects: true },
		} as { [key: string]: { [key: string]: any } },
		originalLocation: null,
		currentLocation: null,
	}),
	getters: {
		getFilters(state) {
			return state.filters;
		},
	},
	actions: {
		async fetchFilters(countryId: number) {
			const res = await useSsrFetch(`pages/projects/filters?countryId=${countryId}`) as FilterItem[];
			this.filters = res;
		},
		async updateDevelopers(location_id: number) {
			const res = await useSsrFetch(`pages/developers/${location_id}`)
			this.filters = this.filters.map(f => {
				if (f._id === 'developer') {
					return {
						...f,
						data_source: res
					};
				}
				return f;
			});
		},
		async fetchCount() {
			axios
				.get(this.filter_url)
				.then((res) => {
					this.count = parseInt(res?.data?.total || 0);
				})
				.catch(() => {
					this.count = 0;
				});
		},
		async fetchData() {
			axios
				.get(this.filter_url)
				.then((res) => {
					this.data = res?.data;
				})
				.catch((error) => {
					this.count = 0;
				});
		},
		async updateValue(filter: FilterItem, val: { min: number | string, max: number | string, label_value: string, slug: string }) {
			if (!val) {
				// reset filter
				this.selected_filters[filter.slug] = {};
				this.updateFilterUrl();
				return;
			}

			if (filter.slug === 'location') {
				// use slug for location
				this.slug = val.slug;
				this.updateFilterUrl();
				return;
			}

			let label = filter.name;
			let value: any = { ...val };


			if (value.min == 0) value.min = "";
			if (value.max == 0) value.max = "";

			if (value.max && value.max != "")
				value.max = helper.currencyFormat(value.max);
			if (value.min && value.min != "")
				value.min = helper.currencyFormat(value.min);

			if (filter.is_range === true) {
				label =
					`${filter.name} ${value.min} : ` +
					(value.max || useNuxtApp().$i18n.t("client.any")); // update locale
			} else if (Array.isArray(val)) {
				label = _.map(val, "label_value").join(",") + " " + filter.name;
			} else {
				label = value.label_value || value.value;
			}
			if (filter.input_type === "select") {
				value = _.map(val, "slug").join(",");
			} else if (value.slug) {
				value = value.slug;
			}

			this.selected_filters[filter.slug] = {
				label: label,
				value: value,
			};

			this.updateFilterUrl();
		},
		async updateFilterUrl() {
			let filter_url = `projects/${this.slug}?`;

			// Append filters if found
			const slugs = this.filters.map((f) => f.slug)
			for (const slug of slugs) {
				const value = this.selected_filters[slug]?.value;
				if (value?.min || value?.max){
					if (value?.min) {
						filter_url += `&${slug}_min=${value.min}`
					}
					if (value?.max) {
						filter_url += `&${slug}_max=${value.max}`
					}
				}else if (value?.min == "" || value?.max == ""){
					continue;
				}
				else if(value) {
					filter_url += `&${slug}=${value}`;
				}
			}

			this.filter_url = filter_url;
		},
		setSelectedFilters(selected_filters: FilterOptions) {
			this.selected_filters = {
				...this.selected_filters,
				...selected_filters,
			};
		},
		updateFilterInputs(params: any) {
			const location = params.location;
			const filters: { [key: string]: string } = params.filters;

			this.slug = location;
			this.originalLocation = location;
			this.currentLocation = location?.split('-').map((word: string) => {
				return word[0].toUpperCase() + word.substring(1);
			}).join(" ");

			if (filters) {
				const filtersMap: any = this.filters.reduce(
					(acc: Object, item: FilterItem) => ({ ...acc, [item.slug]: item.data_source }), {});

				for (const [key, value] of Object.entries(filters)) {
					if (filtersMap[key]) {
						let label = filtersMap[key].find((item: DataSource) => item.slug === value)?.value || value;
						this.selected_filters[key] = { label, value };
					}
					if (key.includes('min') || key.includes('max')) {
						if(key.includes('min')) {
							const key_in_filter = key.split('_min')[0]
							this.selected_filters[key_in_filter] = { label: key, value :{ min: value } }
						}
						if(key.includes('max')) {
							const key_in_filter = key.split('_max')[0]
							this.selected_filters[key_in_filter] = { label: key, value :{ max: value } }
						}
					}
				}
			}

			this.updateFilterUrl();
		},
		setSlug(_slug: string) {
			this.slug = _slug;
		},
		addCustomQuery(label: string, key: string, value: string) {
			this.customQuery[label][key] = value;
		}
	},
});
